.resultListContainer {
    width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.beforeGenesisText {
    color:#FF6600;
    font-size: 12px;
}