.modalBackdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 20;
    background-color: rgba(0, 0, 0, 0.75);
  }

.donationInput {
    background: none;
    border: none;
    border-radius: 5px;
    padding: 5px;
    text-align: center;
    color: #FF6000;
    margin-right: 5px;
}

.donationInputContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #222222;
    padding: 10px;
    border-radius: 2px;
}

.header {
    color: #FF6000;
}

.modal {
    position: fixed;
    top: 10vh;
    width: 100%;
    background-color: #161616;
    padding: 1rem;
    border-radius: 14px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
    z-index: 30;
    animation: slide-down 300ms ease-out forwards;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  @media (min-width: 768px) {
    .modal {
      width: 40rem;
      left: calc(50% - 20rem);
    }
  }
  
  @keyframes slide-down {
    from {
      opacity: 0;
      transform: translateY(-3rem);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .actions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

button {
    background: none;
    font-size: 12px;
    border: #FF6600 1px solid;
    border-radius: 5px;
    color: #FF6600;
    margin: 10px 10px 0px 10px;
    height: 1.5rem;
  }
  
button:hover {
    background-color: #FF6600;
    color: white;
  }

  .sats {
    height: 20px
  }

  .qrcode {
    background-color: white;
    border-radius: 5px;
    padding: 5px;
  }
  
  .donationInvoice {
    width: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .invoiceCopy {
    width: 100%;
    padding: 5px;
  }

  .copyText {
    color:#FF6000;
    font-size: 8px;
  }

  .donationButtons {
    display: flex;

  }

  .qrcodeIcon {
    position: absolute;
    top: 42%
  }