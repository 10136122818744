textarea:focus, input:focus{
  outline: none;
}

.App {
  display: flex;
  flex-direction: column;
  width: 300px;
  align-items: center;
  text-align: center;
  background-color: #161616;
  border-radius: 5px;
  margin-top: 10%;
}

.UTCinfo {
  font-size: 12px;
  color: #FF6600;
  margin-top: 15px;
}

.title {
  color: #FF6600;
  font-size: 12px;
}

.dateSelect {
  justify-content: center;
  align-items: center;
  background-color: #222222;
  border: none;
  text-align: center;
  color: white;
  margin-right: 10px;
  font-size: 18px;
}

.timeSelect {
  justify-content: center;
  align-items: center;
  background-color: #222222;
  border: none;
  text-align: center;
  color: white;
  margin-left: 10px;
  font-size: 18px;
}

.timeSelect:focus {
  border: #FF6600 1px solid;
}

.dateSelect:focus {
  border: #FF6600 1px solid;
}

.selection {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}

.mainbutton {
  background: none;
  border: #FF6600 1px solid;
  border-radius: 5px;
  color: #FF6600;
  font-size: 12px;
  width: 100px;
  margin: 10px;
}

.mainbutton:hover {
  background-color: #FF6600;
  color: white;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.lightning {
  height: 15px;
}

.donationBox:hover {
  cursor: pointer;
}

.invalid {
  color: red !important;
}

.errorMessage {
  color: red;
  font-size: 12px;
}

.donationBox{
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #222222;
  border-radius: 5px;
  padding-left: 5px;
  padding-right: 5px;
  color: #FF6600;
}

.appHeader {
  display: flex;
  background-color: #212124;
  height: 20px;
  width: 100%;
  align-items: center;
  border-radius: 5px 5px 0 0;
  justify-content: space-between;
}

.appButtons {
  display: flex;
  margin-left: 5px;
}

.appButton {
  background-color: #FF6600;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  margin: 2px;
}

.appButtonsHidden {
  display: flex;
  margin-left: 5px;
}

.appButtonHidden {
  background-color: #FF6600;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  margin: 2px;
  visibility: hidden;
}

.result{
  background-color: #161616;
  margin: 10px;
  color: white;
}

.donationFooter {
  color: white;
  font-size: 10px;
}

.donationParagraph {
  color: #FF6600;
  text-decoration: underline;
}

.donationParagraph:hover {
  color: #FF6600;
  text-decoration: underline;
  cursor: pointer;
}

.beforeGenesisContainer {
  max-width: 300px;
  color: #FF6600;
}

.beforeGenesisText {
  color:#FF6600;
  font-size: 12px;
}

.switchContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 250px;
}

.switchText {
  color: #333333;
  font-size: 12px;
}

.switchTextActive {
  color: #FF6600;
}