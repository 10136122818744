.resultContainer {
    background-color: #161616;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    padding: 15px;
    align-items: center;
    width: 270px;
    margin-top: 15px; 
}

.resultBlock {
    display: flex;
    border: #FF6000 1px solid;
    color: #FF6000;
    width: 100px;
    height: 100px;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 2px;
}

.resultBlockText {
    font-size: 16px;

}

.resultTime {
    color: #FF6000;
    margin-right: 10px;
}

a {
    color: #FF6000;
    
}