.resultContainer {
    background-color: #161616;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    padding: 15px;
    align-items: center;
    width: 270px;
    margin-top: 15px; 
}

.resultBlock {
    display: flex;
    border: #FF6000 1px solid;
    background-color: #FF6000;
    color: #FF6000;
    width: 50px;
    height: 50px;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 2px;
}

.resultBlockText {
    font-size: 8px;
    color: #161616;
    font-weight: 800;
}

.resultTime {
    color: #FF6000;
    font-size: 10px;
    width: 100%;
}

a {
    color: #FF6000;
    
}

.timeContainer {
    display: flex;
    flex-direction: column;
    width: 70%;
    align-items: flex-end;
    text-align: end;
}